import React from 'react';
import {useMediaQuery} from "react-responsive";
import {Col, Container, Flex, Grid, Row} from "@modbros/components/layout";
import Colors from "@modbros/design-tokens/colors";
import styled from "styled-components";
import Spacings from "@modbros/design-tokens/spacings";
import {CheckIcon, CloseIcon, DownloadIcon} from "@modbros/icons";
import {Heading, Text} from "@modbros/components/typography";
import {Button} from "@modbros/components/form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPatreon} from "@fortawesome/free-brands-svg-icons";


export const PriceTable = () => {

    return (
        <Container paddingY={64} marginY={64}>
            <Flex flexDirection="column">
                <TableFeatureHeading headlines={['Free', 'Bro', 'Super Bro']}/>
                <TableFeatureRow tiers={['FREE', '3€', '11€']}>Price</TableFeatureRow>
                <TableFeatureRow tiers={['1', '4', '10']}>Dashboards</TableFeatureRow>
                <TableFeatureRow tiers={['2s', '1s', '0.5s']}>Dashboard update frequency:</TableFeatureRow>
                <TableFeatureRow tiers={['25', '75', '250']}>Metrics per dashboard:</TableFeatureRow>
                {/*<TableFeatureRow tiers={[false, false, '100', 'Unlimited']}>Api access:</TableFeatureRow>*/}
                <TableFeatureRow tiers={[false, true, true]}>Early access to new features:</TableFeatureRow>
                <TableFeatureRow tiers={[false, undefined, true]}>Premium widget packs and/or
                    plugins:</TableFeatureRow>
                <TableFeatureRow tiers={[false, true, true]}>Separate forum badge & Discord channel</TableFeatureRow>
                <TableFeatureRow tiers={[false, false, true]}>Personal Discord support</TableFeatureRow>
                <PatreonButtonRow tierId={["", "5146665", "5229382"]}></PatreonButtonRow>
            </Flex>
        </Container>
    )
}

const TableFeatureHeading = (props: { headlines: string[]; }) => {
    const {
        headlines,
    } = props;

    const isAboveMobileSize = useMediaQuery({minWidth: 991})

    const columns = `33% ${headlines.map(() => `1fr `).join("")}`

    return (
        <Grid style={{gridTemplateColumns: columns, display: isAboveMobileSize ? 'grid' : 'none'}} gap={0}>
            <span></span>
            {headlines.map((headline) =>
                <Heading level={4}>{headline}</Heading>
            )}
        </Grid>
    )
}


const StyledFeatureRow = styled('div')`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    border-bottom: 1px solid ${Colors.Border.Default};
    height: -moz-fit-content;
    height: fit-content;
    justify-content: flex-end;
    padding: ${Spacings.Size20} 0;
    width: 100%;
    @media (max-width: 991px) {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;

        > div:first-child {
            flex: 1 1 100%;
        }
    }
`
const StyledMobileHeading = styled('span')`
    display: none;
    @media (max-width: 991px) {
        display: block;
    }
`
const PatreonButtonRow = (props: {
    children?: React.ReactElement | string,
    tierId: Array<string>
}) => {
    const {
        children,
        tierId
    } = props

    const columns = `33% ${tierId.map(() => `1fr `).join("")}`

    return (
        <StyledFeatureRow style={{gridTemplateColumns: columns}}>
            <Flex>
                {children}
            </Flex>
            {tierId.map((tier, index) => {
                    if (tier) {
                        return <Flex flex={2} flexDirection="column">
                            <StyledMobileHeading>{getTierFromIndex(index)}</StyledMobileHeading>
                            <Button prefixIcon={<FontAwesomeIcon style={{width: "20px", height: "20px", paddingTop: "1px"}}
                                                                 icon={faPatreon}/>} as={'a'} target="_blank"
                                    rel="noreferrer"
                                    href={`https://www.patreon.com/checkout/4448400/${tier}/`}
                                    className="link-unstyled" style={{
                                background: '#f96854',
                                "--modbros-color-text-emphasized": '#fff'
                            } as React.CSSProperties}>
                                Patreon
                            </Button>

                        </Flex>
                    }
                    return <Flex flex={2} flexDirection="column">
                        <StyledMobileHeading>{getTierFromIndex(index)}</StyledMobileHeading>
                        <Button className="link-unstyled" as={'a'} href="https://update.v1.mobro.app/download/latest"
                                target="_blank" prefixIcon={<DownloadIcon/>} variant="primary">Install</Button>
                    </Flex>
                }
            )
            }
        </StyledFeatureRow>
    )
}

const TableFeatureRow = (props: {
    children?: React.ReactElement | string,
    tiers: Array<string | boolean | undefined>
}) => {
    const {
        children,
        tiers
    } = props

    const columns = `33% ${tiers.map(() => `1fr `).join("")}`

    return (
        <StyledFeatureRow style={{gridTemplateColumns: columns}}>
            <Flex>
                {children}
            </Flex>
            {tiers.map((tier, index) =>
                <Flex flex={2} flexDirection="column">
                    <StyledMobileHeading>{getTierFromIndex(index)}</StyledMobileHeading>
                    {typeof tier === "string" ? tier : getTierIcon(tier)}
                </Flex>
            )}
        </StyledFeatureRow>
    )
}

const getTierFromIndex = (index: number) => {
    switch (index) {
        case 0:
            return "Free"
        case 1:
            return "Bro"
        case 2:
            return "Super Bro"
    }
}

const getTierIcon = (tier: boolean | undefined) => {

    if (tier === undefined) {
        return (
            <Flex alignItems="center">
                <CheckIcon/>
                <Text textStyle="small">&nbsp;(some)</Text>
            </Flex>
        )
    }
    if (tier === false) {
        return (
            <CloseIcon/>
        )
    }
    return (
        <CheckIcon/>
    )
}