import React from 'react';
import { Flex, Container, Row, Col } from '@modbros/components/layout'
import styled from "styled-components";
import logo from "../mobro-logo.svg";
import {Heading, Link, Text} from "@modbros/components/typography";
import { Button } from "@modbros/components/form";
import { Button as DTButton } from "@dynatrace/strato-components-preview";
import { DownloadIcon } from "@modbros/icons";
import Spacings from '@modbros/design-tokens/spacings'
import { faLinux, faApple } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from 'react-responsive'
import {motion, Variants} from 'framer-motion'
import {DevicesSvg} from "../inline-svgs/DevicesSvg";


const StyledContainer = styled(Flex)`
  min-height: 75vh;
  max-height: 1000px;
  padding: ${Spacings.Size32} 0;
  width: 100%;
  background: radial-gradient(50.79% 51.88% at -10.62% 13.6%, rgba(236, 37, 17, 0.2) 0%, rgba(31, 31, 37, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(54.86% 66.17% at 102.25% 91.52%, #3F3F5A 0%, #1F1F26 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
`

const StyledHeroImage = styled.div`
  position: absolute;
  max-width: 500px;
  height: auto;
  
  z-index: 0;
  left: 50%;
  top: 50%;
  
  transform: translateY(-20%);

  @media (max-width: 768px) {
      display: none;
  }
  
  @media (max-width: 992px) {
    transform: translateY(-10%);
  }
`

const containerVariants: Variants = {
    offscreen: { opacity: 0 },
    onscreen: {
        opacity: 1,
        transition: {
            staggerChildren: 0.15,
        }
    }
}
const fadeIn: Variants = {
    offscreen: {
        y: 25,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            duration: .6

        }
    }
}

export const PageHero = () => {

    const isAboveDesktopBigSize = useMediaQuery({minWidth: 1400})
    const isAboveDesktopSize = useMediaQuery({minWidth: 1200})
    const isAboveTabletSize = useMediaQuery({minWidth: 992})
    const isAboveMobileSize = useMediaQuery({minWidth: 768})
    const isAboveMobileSmallSize = useMediaQuery({minWidth: 576})

    const MotionFlex = motion(Flex);

    // @ts-ignore
    return(
        <StyledContainer>
            <Container>
                <Row height="100%" style={{position: "relative"}}>
                    <Col span={isAboveDesktopBigSize ? 5 : isAboveDesktopSize ? 6 : 12} height="100%" style={{zIndex: 1}}>
                        <MotionFlex
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8}}
                            variants={containerVariants}
                            height="100%" flexDirection="column" alignItems="flex-start" justifyContent="center">
                            <motion.img variants={fadeIn} height={50} src={logo} alt="MoBro-Logo" />
                            <MotionFlex variants={fadeIn} flexDirection='column'>
                                <Heading level={isAboveTabletSize ? 1 : 2} useTag="h1" headingColor="Emphasized" >Universal Data Visualization</Heading>
                                <Text >{"Create real-time monitoring Dashboards with drag & drop."}</Text>
                            </MotionFlex>
                            <MotionFlex variants={fadeIn} flexDirection="column" mt={24}>
                                <Button className="link-unstyled hero-button" as={"a"} href="https://update.v1.mobro.app/download/latest" target="_blank" prefixIcon={<DownloadIcon/>} variant="primary">Download MoBro</Button>
                                {/*<Flex justifyContent="space-between">*/}
                                {/*    <Text textStyle="small">Soon available on:</Text>*/}
                                {/*    <Flex gap={12}>*/}
                                {/*        <FontAwesomeIcon icon={faLinux} />*/}
                                {/*        <FontAwesomeIcon icon={faApple} />*/}
                                {/*    </Flex>*/}
                                {/*</Flex>*/}
                                {/*@ts-ignore */}
                            </MotionFlex>
                        </MotionFlex>
                    </Col>
                    <StyledHeroImage >
                        <DevicesSvg/>
                    </StyledHeroImage>
                </Row>
            </Container>
        </StyledContainer>
    )
}